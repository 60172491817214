import { Box, SxProps, Theme } from '@mui/material';

import Text from 'components/primitives/Text';

interface Props {
  color?: 'blue' | 'green' | 'red' | 'gray';
  size?: 'small' | 'default';
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

export default function Tag({
  color = 'blue',
  size = 'default',
  children,
  sx,
}: Props) {
  const sizes = {
    small: {
      px: '4px',
      py: '2px',
    },
    default: {
      px: '6px',
      py: '2px',
    },
  };

  const colors = {
    blue: {
      bgcolor: 'var(--badge-info-bg)',
      color: 'var(--badge-info-text)',
    },
    green: {
      bgcolor: 'var(--badge-success-bg)',
      color: 'var(--badge-info-text)',
    },
    red: {
      bgcolor: 'var(--badge-error-bg)',
      color: 'var(--badge-error-text)',
    },
    gray: {
      bgcolor: 'var(--border-primary)',
      color: 'var(--badge-neutral-text)',
    },
  };

  return (
    <Box
      sx={{
        bgcolor: colors[color].bgcolor,
        borderRadius: '4px',
        display: 'inline-block',
        ...sizes[size],
        ...sx,
      }}
    >
      <Text
        size={size === 'small' ? 'xxs' : 'xs'}
        sx={{
          color: colors[color].color,
        }}
        weight="medium"
      >
        {children}
      </Text>
    </Box>
  );
}
