import { SubscriptionType } from 'types/TeamManagement/subscription';

export default function getSubscriptionPlanName(plan: SubscriptionType) {
  switch (plan) {
    case SubscriptionType.None:
      return 'None';
    case SubscriptionType.Website:
      return 'Website';
    case SubscriptionType.Basic:
      return 'Basic';
    case SubscriptionType.Growth:
      return 'Growth';
    case SubscriptionType.Enterprise:
      return 'Enterprise';
    case SubscriptionType.GrowthKolIntelligence:
      return 'Growth+';
    case SubscriptionType.KolIntelligence:
      return 'Website+';
    case SubscriptionType.BasicKolIntelligence:
      return 'Basic+';
    default:
      return 'None';
  }
}
