import { ComponentProps } from 'react';
import { Box } from '@mui/material';

import useDetectOverflow from 'utils/hooks/useDetectOverflow';

interface Props extends ComponentProps<typeof Box> {
  children: React.ReactNode;
  disableDetectOverflow?: boolean;
}

export default function Scrollable({
  disableDetectOverflow = true,
  ...props
}: Props) {
  const { isOverflowing, isScrolledToBottom, ref } = useDetectOverflow();

  return (
    <Box height={props.height} sx={{ position: 'relative' }}>
      <Box
        {...props}
        ref={ref}
        sx={{
          overflowY: 'auto',
          overflowX: 'hidden',
          height: '100%',
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          width: '100%',
          pb: 2,
          ...props.sx,
        }}
      >
        {props.children}
        {!disableDetectOverflow && isOverflowing && !isScrolledToBottom && (
          <Box
            sx={{
              position: 'absolute',
              bottom: '0',
              left: '0',
              right: '0',
              height: '40px',
              background:
                'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
            }}
          />
        )}
      </Box>
    </Box>
  );
}
