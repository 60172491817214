import { cn } from 'src/utils';

function Skeleton({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn(
        [
          'group',
          'relative',
          'overflow-hidden',
          'bg-ds-secondary',
          'pointer-events-none',
          // before
          'before:opacity-100',
          'before:absolute',
          'before:inset-0',
          'before:-translate-x-full',
          'before:animate-[shimmer_2s_infinite]',
          'before:border-t',
          'before:border-b',
          'before:border-ds-border-secondary',
          'before:bg-gradient-to-r',
          'before:from-transparent',
          'before:via-neutral-200/70 dark:before:via-neutral-900/70',
          'before:to-transparent',
          //after
          'after:opacity-100',
          'after:absolute',
          'after:inset-0',
          'after:-z-10',
          'after:bg-content3',
          'dark:after:bg-content2',
          // state
          'data-[loaded=true]:pointer-events-auto',
          'data-[loaded=true]:overflow-visible',
          'data-[loaded=true]:!bg-transparent',
          'data-[loaded=true]:before:-z-10 data-[loaded=true]:before:animate-none data-[loaded=true]:before:opacity-0',
          'data-[loaded=true]:after:opacity-0',
          'transition-background !duration-300',
          'rounded-lg',
        ],
        className,
      )}
      {...props}
    >
      &nbsp;
    </div>
  );
}

export { Skeleton };
