import { useTheme } from 'next-themes';
import { Moon, Sun } from 'lucide-react';
import { Button } from 'src/shared/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from 'src/shared/ui/dropdown-menu';
import { useIsLoggedIn } from 'src/store/AppProvider';
import { useTeamStore } from 'src/store/team/teamStore';
import { cn } from 'src/utils';

import SelectTeamDropdown from 'common/user/team/SelectTeamDropdown';
import TeamDropdown from 'components/team-management/TeamDropdown';
import { useGetTeams } from 'services/teamManagement/useGetTeams';

import TeamDropdownTrigger from '../../../common/user/team/SelectTeamDropdown/components/team-dropdown-trigger';
import FeedbackButton from '../FeedbackButton';
import GoToPricingButton from '../GoToPricingButton';
import DesktopHeaderDemoTeamOverlay from '../HeaderDemoTeamOverlay/desktop';
import MobileHeaderDemoTeamOverlay from '../HeaderDemoTeamOverlay/mobile';
import HelpButton from '../HelpButton';
import TokenSearch from '../TokenSearch';

interface Props {
  shouldShowTeamChooser?: boolean;
}

export default function Navigation({ shouldShowTeamChooser = true }: Props) {
  const { selectedTeam } = useGetTeams();
  const isLoggedIn = useIsLoggedIn();

  const { setTheme, theme } = useTheme();

  return (
    <div className="sticky top-0 z-20 max-lg:hidden">
      <div
        className={cn(
          'relative top-0 z-0 flex h-12 w-full items-center justify-between border-b border-ds-border-primary bg-ds-primary lg:sticky',
          selectedTeam?.isDemo && 'bg-ds-alert-error-bg',
        )}
      >
        {selectedTeam?.isDemo && <DesktopHeaderDemoTeamOverlay />}
        <div className="sticky flex h-full items-center justify-between px-2 lg:w-full lg:px-2">
          <div className=" hidden items-center gap-2 lg:flex">
            {shouldShowTeamChooser && <SelectTeamDropdown />}
            {/* {isLoggedIn && <UpgradePlanButton />} */}
          </div>
          <div className="flex items-center gap-1 lg:gap-2">
            {isLoggedIn && (
              <div className="hidden sm:block">
                <TokenSearch />
              </div>
            )}
            <Button
              onClick={() => {
                setTheme(theme === 'dark' ? 'light' : 'dark');
              }}
              intent="neutral"
              size="xs"
            >
              {theme === 'dark' ? <Sun /> : <Moon />}
            </Button>
            <GoToPricingButton />
          </div>
        </div>
      </div>
      {selectedTeam?.isDemo && <MobileHeaderDemoTeamOverlay />}
    </div>
  );
}
