import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useTeamStore } from 'src/store/team/teamStore';

import { TeamResItem } from 'services/teamManagement/useGetTeams';

const useOnSelectTeam = () => {
  const router = useRouter();

  const {
    setTeam,
    team: currentlyPickedTeam,
    setProject,
    setTeamAndProject,
  } = useTeamStore();

  const onTeamClick = useCallback(
    async (team: TeamResItem) => {
      if (team.id === currentlyPickedTeam?.id) return;
      setTeam(team);

      if (team.projects.length > 0) {
        setProject(team.projects[0]);
      }

      // Change the URL only if the route contains [teamSlug] or [projectSlug]
      const route = router.route;

      const routeHasTeamSlug = route.includes('[teamSlug]');
      const routeHasProjectSlug = route.includes('[projectSlug]');

      let updatedRoute = window.location.pathname;

      if (routeHasTeamSlug) {
        const currentTeamSlug = router.query.teamSlug as string;
        updatedRoute = updatedRoute.replace(currentTeamSlug, team.slug);
      }

      if (routeHasProjectSlug) {
        const currentProjectSlug = router.query.projectSlug as string;
        updatedRoute = updatedRoute.replace(
          currentProjectSlug,
          team.projects[0].slug,
        );
      }

      if (routeHasProjectSlug || routeHasTeamSlug) {
        setTeamAndProject(team, team.projects[0]);
        window.location.href = updatedRoute;
      } else {
        // If the route doesn't contain [teamSlug] or [projectSlug], we need to reload the page
      }
    },
    [currentlyPickedTeam, router, setProject, setTeam, setTeamAndProject],
  );

  return onTeamClick;
};

export default useOnSelectTeam;
