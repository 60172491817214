import React from 'react';

import {
  Command,
  CommandGroup,
  CommandInput,
  CommandList,
  CommandShortcut,
} from 'components/ui/command';
import { ScrollArea, ScrollBar } from 'components/ui/scroll-area';
import { useGetTokenDetails } from 'services/tokenDetails/useGetTokenDetails';
import { Token } from 'types/Token';

import { SUGGESTED_TOKENS } from '../../base/suggestedTokens';
import { useManageRecentlySelected } from '../hooks/useManageRecentlySelected';
import ExplorerSearchDropdownRecentlySearched from './RecentlySearched';
import ExplorerSearchDropdownTokensSection from './TokensSection';

export default function ExplorerSearchDropdown() {
  const [value, setValue] = React.useState('');

  const { data, error, isLoading, handleSearch, debouncedSearchQuery } =
    useGetTokenDetails();

  const { recentlySelected } = useManageRecentlySelected();

  return (
    <div className="h-full">
      <Command className="h-max" shouldFilter={false}>
        <section className="w-full items-center gap-2 ">
          <CommandInput
            onValueChange={(e) => {
              setValue(e);
              handleSearch(e);
            }}
            value={value}
            placeholder="Search for token, NFT or audience"
            className="w-full  py-1 text-sm outline-none"
          />
        </section>
        <CommandList className="h-full max-h-full">
          <CommandGroup value="Tokens" heading="Recommended">
            <ExplorerSearchDropdownTokensSection
              tokens={
                value === ''
                  ? (SUGGESTED_TOKENS as Token[]).slice(-6).reverse()
                  : data
              }
              isLoading={isLoading}
            />
          </CommandGroup>

          {recentlySelected.length > 0 && (
            <CommandGroup heading="Recent searches">
              <ScrollArea className="whitespace-nowrap pb-3">
                <ExplorerSearchDropdownRecentlySearched />
                <ScrollBar orientation="horizontal" className="" />
              </ScrollArea>
            </CommandGroup>
          )}
        </CommandList>
        <div className="flex items-center gap-3 bg-ds-secondary px-3 py-2">
          <div className="flex items-center gap-1">
            <CommandShortcut className="ml-0 rounded bg-neutral-400 px-1 ">
              ESC
            </CommandShortcut>
            <span className="text-xs text-c-tertiary">to cancel</span>
          </div>
          <div className="flex items-center gap-1">
            <CommandShortcut className="ml-0 rounded bg-neutral-400 px-1 ">
              ↵
            </CommandShortcut>
            <span className="text-xs text-c-tertiary">to select</span>
          </div>
          <div className="ml-auto flex items-center gap-1">
            <CommandShortcut className="ml-0 rounded bg-neutral-400 px-1 ">
              ↑
            </CommandShortcut>
            <CommandShortcut className="ml-0 rounded bg-neutral-400 px-1 ">
              ↓
            </CommandShortcut>
            <span className="text-xs text-c-tertiary">to navigate</span>
          </div>
        </div>
      </Command>
    </div>
  );
}
