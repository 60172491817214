import { ChevronsUpDownIcon } from 'lucide-react';
import { Badge } from 'src/shared/ui/badge';
import { Button } from 'src/shared/ui/button';

import getSubscriptionPlanName from 'utils/teamManagement/getSubscriptionPlanName';
import { TeamResItem, useGetTeams } from 'services/teamManagement/useGetTeams';

const TeamDropdownTrigger = ({
  selectedTeam,
}: {
  selectedTeam: TeamResItem | undefined;
}) => {
  if (!selectedTeam) {
    return (
      <Button size="s" intent="neutral" className="pr-2 text-xs">
        <span className="mr-0.5 text-ds_2xs text-ds-text-quarternary">
          Team:
        </span>{' '}
        Select Team <ChevronsUpDownIcon className="ml-2" size={16} />
      </Button>
    );
  }
  return (
    <Button size="s" intent="neutral" className="pr-2 text-xs">
      <span className="mr-0.5 text-ds_2xs text-ds-text-quarternary">Team:</span>{' '}
      {selectedTeam.name}{' '}
      {selectedTeam.subscriptionType && selectedTeam.isSubscriptionActive ? (
        <Badge size="s" intent="success" className="ml-1">
          {getSubscriptionPlanName(selectedTeam.subscriptionType)}
        </Badge>
      ) : (
        <Badge className="ml-1" size="s" intent="tertiary">
          Free
        </Badge>
      )}{' '}
      <ChevronsUpDownIcon className="ml-2" size={16} />
    </Button>
  );
};

export default TeamDropdownTrigger;
