import React from 'react';
import { PiBell, PiCaretUpDown } from 'react-icons/pi';
import { useRouter } from 'next/router';
import { Box } from '@mui/material';
import chroma from 'chroma-js';
import { useInitializeChainStore } from 'src/store/chain/hooks/useInitializeChainStore';
import { useInitializeTeamStore } from 'src/store/team/hooks/useInitializeTeamStore';
import { cn } from 'src/utils';

import Tag from 'common/Tag';
import { onboardingSteps } from 'components/Dashboard/constants/onboardingSteps';
import FeedbackButton from 'components/Dashboard/FeedbackButton';
import GoToPricingButton from 'components/Dashboard/GoToPricingButton';
import DesktopHeaderDemoTeamOverlay from 'components/Dashboard/HeaderDemoTeamOverlay/desktop';
import MobileHeaderDemoTeamOverlay from 'components/Dashboard/HeaderDemoTeamOverlay/mobile';
import HelpButton from 'components/Dashboard/HelpButton';
import MobileHeader from 'components/Dashboard/MobileHeader';
import TokenSearch from 'components/Dashboard/TokenSearch';
import UpgradePlanButton from 'components/Dashboard/UpgradePlanButton';
import OnboardingWrapper from 'components/Onboarding/hooks/useOnboarding';
import Button from 'components/primitives/Button';
import HStack from 'components/primitives/HStack';
import Text from 'components/primitives/Text';
import TeamDropdown from 'components/team-management/TeamDropdown';
import { sideBarDesktopWidth } from 'utils/constants';
import { useDisclosure } from 'utils/hooks';
import { TeamResItem, useGetTeams } from 'services/teamManagement/useGetTeams';

import TeamDropdownTrigger from '../../common/user/team/SelectTeamDropdown/components/team-dropdown-trigger';
import { useIsLoggedIn } from '../../store/AppProvider';
import Navigation from './Navigation';
import NewSidebar from './NewSidebar';
import MobileSidebar from './NewSidebar/Mobile';

export interface DashboardProps {
  children?: React.ReactNode;
  pageName?: string;
  shouldShowTeamChooser?: boolean;
  teamChooserTheme?: 'light' | 'dark';
  containerClassName?: string;
}

export default function Dashboard({
  children,
  shouldShowTeamChooser = true,
  teamChooserTheme = 'dark',
  containerClassName,
  ...props
}: DashboardProps) {
  useInitializeTeamStore();
  useInitializeChainStore();

  const router = useRouter();
  const isLoggedIn = useIsLoggedIn();
  const mobileNavigationDisclosure = useDisclosure();
  const { selectedTeam } = useGetTeams();

  return (
    <div className="">
      <Box
        display={{ xs: 'block', lg: 'grid' }}
        gridTemplateColumns={'auto minmax(0, 1fr)'}
        width="100%"
        height="100%"
        sx={{ background: 'var(--primary-ds)' }}
      >
        <Box
          display={{
            xs: 'none',
            lg: 'block',
          }}
          component="aside"
          width={sideBarDesktopWidth}
          position="sticky"
          height="100vh"
          left={0}
          top={0}
          zIndex={9999}
          flexShrink={0}
        >
          <NewSidebar />
        </Box>

        <MobileHeader onBurgerMenuClick={mobileNavigationDisclosure.open} />

        <div className={containerClassName}>
          <Navigation />
          <div>{children}</div>
        </div>
      </Box>
      <MobileSidebar mobileNavigationDisclosure={mobileNavigationDisclosure} />
    </div>
  );
}
