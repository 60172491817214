import { useState } from 'react';
import Link from 'next/link';
import { Role } from '@prisma/client';
import { Cog, Plus, UserPlus } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuRadioGroup,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'src/shared/ui/dropdown-menu';
import { Input } from 'src/shared/ui/form/input';
import { useAppStateContext, useIsLoggedIn } from 'src/store/AppProvider';

import UnstyledLink from 'common/UnstyledLink';
import { useGetTeams } from 'services/teamManagement/useGetTeams';

import SelectTeamDropdownItem from './components/team-dropdown-item';
import TeamDropdownTrigger from './components/team-dropdown-trigger';
import useOnSelectTeam from './hooks/useSelectTeam';

/*
  ! TODO: Correct links in the dropdown menu items
*/

export default function SelectTeamDropdown() {
  const { selectedTeam, isLoading, teams } = useGetTeams();
  const onSelectTeam = useOnSelectTeam();

  const { user } = useAppStateContext();
  const [inputValue, setInputValue] = useState('');

  const isAdmin = user.data?.roles
    ?.map((role) => role.role)
    .includes(Role.ADMIN);

  const filteredTeams = teams.filter((team) =>
    team.name.toLowerCase().includes(inputValue.toLowerCase()),
  );

  const isLoggedIn = useIsLoggedIn();

  if (!isLoggedIn) return null;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger disabled={isLoading}>
        <TeamDropdownTrigger selectedTeam={selectedTeam} />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" className=" z-[9999999999] w-64">
        {isAdmin && (
          <div className="border-b">
            <Input
              autoFocus
              value={inputValue}
              onKeyDown={(e) => {
                e.stopPropagation();
                // e.preventDefault();
              }}
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setInputValue(e.target.value);
              }}
              placeholder="Search..."
              className="-mt-2 h-8 rounded-none border-none"
            />
          </div>
        )}
        <DropdownMenuGroup>
          {filteredTeams.map((team) => (
            <SelectTeamDropdownItem
              key={team.id}
              team={team}
              isSelected={selectedTeam?.id === team.id}
              onClick={() => onSelectTeam(team)}
            />
          ))}
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <UnstyledLink href="/settings/members" navigateWithinTeam>
            <DropdownMenuItem>
              <UserPlus /> Add team member
            </DropdownMenuItem>
          </UnstyledLink>
          <UnstyledLink href="/settings/team-management">
            <DropdownMenuItem>
              <Cog /> Manage teams
            </DropdownMenuItem>
          </UnstyledLink>
          {isAdmin && (
            <UnstyledLink href="/admin">
              <DropdownMenuItem>
                <Cog /> Go to admin
              </DropdownMenuItem>
            </UnstyledLink>
          )}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
