import { Box } from '@mui/material';
import { Badge } from 'src/shared/ui/badge';

import Text from 'components/primitives/Text';

import { Chain } from '../chains.config';

interface Props extends Chain {
  fullWidth?: boolean;
  size?: 'xxs' | 'xs' | 'sm';
}

export default function ChainTile({
  name,
  primaryColor,
  secondaryColor,
  IconSm,
  fullWidth,
  size = 'sm',
}: Props) {
  const getStyle = () => {
    switch (size) {
      case 'xxs':
        return {
          height: '16px',
          padding: '4px 6px',
          borderRadius: '6px',
        };
      case 'xs':
        return {
          height: '24px',
          padding: '4px 8px',
          borderRadius: '4px',
        };
      case 'sm':
        return {
          height: '28',
          padding: '4px 12px',
          borderRadius: '8px',
        };
    }
  };

  return (
    <Badge size="s" intent="inverted">
      <div className="scale-75 dark:invert">
        <IconSm />
      </div>
      {name}
    </Badge>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexShrink: 0,
      }}
    >
      <Box
        sx={{
          display: fullWidth ? 'flex' : 'inline-flex',
          flexGrow: fullWidth ? 1 : 0,
          alignItems: 'center',
          columnGap: size === 'xxs' ? '4px' : '8px',
          background: `linear-gradient(180deg, ${primaryColor} 0%, ${secondaryColor} 100%)`,
          ...getStyle(),
          '& svg': {
            transform: size === 'xxs' ? 'scale(0.75)' : 'scale(1)',
          },
        }}
      >
        <IconSm />
        <Text color="white" size={size}>
          {name}
        </Text>
      </Box>
    </Box>
  );
}
