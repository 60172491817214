import React from 'react';
import { Box } from '@mui/material';

import Text from 'components/primitives/Text';

export default function DesktopHeaderDemoTeamOverlay() {
  return (
    <Box
      display={{
        xs: 'none',
        lg: 'block',
      }}
    >
      <p className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-sm text-ds-text-error">
        You are viewing the Cookie3 app in demo mode. Change your team to view
        your own data.
      </p>
    </Box>
  );
}
