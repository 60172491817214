import React from 'react';
import Link from 'next/link';
import { Box } from '@mui/material';
import { ArrowUpRightFromCircle, ArrowUpRightFromSquare } from 'lucide-react';
import { Button } from 'src/shared/ui/button';

import SlashButton from 'components/primitives/Button/SlashButton';
import Text from 'components/primitives/Text';

export default function GoToPricingButton() {
  return (
    <Link href="/plans">
      <Button size="xs" intent="brand3">
        View plans <ArrowUpRightFromSquare />
      </Button>
    </Link>
  );
}
