import TokenIcon from 'common/TokenIcon';
import UnstyledLink from 'common/UnstyledLink';
import { CommandItem } from 'components/ui/command';

import { useManageRecentlySelected } from '../../hooks/useManageRecentlySelected';

export default function ExplorerSearchDropdownRecentlySearched() {
  const { recentlySelected } = useManageRecentlySelected();

  return (
    <div className="flex w-max items-center gap-2 pb-2 pl-4">
      {recentlySelected.map((option, index) => {
        if ('address' in option) {
          return (
            <UnstyledLink
              href={`/token/${option.chain}/${option.address}`}
              navigateWithinTeam
              key={option.address}
            >
              <CommandItem
                value={option.address}
                className="flex h-full w-24 shrink-0 flex-col items-center justify-center overflow-hidden overflow-ellipsis whitespace-nowrap rounded-lg border border-ds-border-primary bg-ds-secondary  p-2 text-center transition-all hover:scale-95 hover:shadow-md"
              >
                <TokenIcon
                  size={24}
                  address={option.address}
                  symbol={option.symbol}
                  type="token"
                  chainId={option.chain}
                />
                <p className="mt-2 max-w-20 overflow-hidden overflow-ellipsis text-xs font-medium">
                  {option.symbol}
                </p>
                <p className="max-w-20 overflow-hidden overflow-ellipsis text-center text-[10px] text-c-tertiary">
                  {option.name}
                </p>
              </CommandItem>
            </UnstyledLink>
          );
        }
        if ('audienceDefinition' in option) {
          return (
            <div key={option.audienceDefinition.id}>
              <div>Audience</div>
            </div>
          );
        }
      })}
    </div>
  );
}
