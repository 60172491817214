import { PiMaskSad } from 'react-icons/pi';
import { useChainStore } from 'src/store/chain/chainStore';

import TokenIcon from 'common/TokenIcon';
import ConditionalLink from 'components/Dashboard/ConditionalLink';
import { CommandItem } from 'components/ui/command';
import { ScrollArea } from 'components/ui/scroll-area';
import { Skeleton } from 'components/ui/skeleton';
import { CHAINS } from 'components/views/campaign-manager/goals/chains.config';
import ChainTile from 'components/views/campaign-manager/goals/common/ChainTile';
import { Token } from 'types/Token';

import { useManageRecentlySelected } from '../../hooks/useManageRecentlySelected';

interface Props {
  tokens?: Token[];
  isLoading?: boolean;
}

export default function ExplorerSearchDropdownTokensSection(props: Props) {
  const chainStore = useChainStore();
  const { updateRecentlySelectedOptions } = useManageRecentlySelected();

  if (props.isLoading) {
    return (
      <section className="">
        <div className="flex h-[220px] flex-col gap-1">
          {[...Array(6)].map((_, id) => {
            return (
              <div
                key={id}
                className="flex items-center gap-2 px-4 py-1.5 text-sm font-medium hover:bg-neutral-200 "
              >
                <Skeleton className="h-[20px] w-[20px] rounded-full" />
                <Skeleton className="ml-1 w-[80px]">&nbsp;</Skeleton>
                <Skeleton className="mt-0.5 w-[40px] text-xs text-c-tertiary">
                  &nbsp;
                </Skeleton>
                <Skeleton className="ml-auto w-[80px]">&nbsp;</Skeleton>
              </div>
            );
          })}
        </div>
      </section>
    );
  }

  if (props.tokens?.length === 0) {
    return (
      <div className="h-[220px]">
        <div className="flex h-full flex-col items-center justify-center">
          <PiMaskSad className="text-c-tertiar2 mb-2 text-3xl" />
          <p className="text-xs font-medium text-c-tertiary">
            No results found.
          </p>
        </div>
      </div>
    );
  }

  return (
    <ScrollArea className="h-[220px]">
      {props.tokens?.map((token, index) => {
        const chain = CHAINS.get(token.chain);

        return (
          <ConditionalLink
            condition={true}
            navigateWithinTeam
            href={`/token/${token.chain}/${token.address}`}
            key={token.address + token.chain + token.symbol + token.name}
          >
            <CommandItem
              value={
                token.address + token.chain + token.symbol + token.name + index
              }
              key={
                token.address + token.chain + token.symbol + token.name + index
              }
              onClick={() => {
                chainStore.setChain(token.chain);
                updateRecentlySelectedOptions(token);
              }}
              className="flex items-center gap-2 px-4 py-1.5 text-sm font-medium hover:bg-neutral-200 "
              onSelect={() => {
                updateRecentlySelectedOptions(token);
              }}
            >
              <TokenIcon
                size={20}
                address={token.address}
                symbol={token.symbol}
                type="token"
                chainId={token.chain}
              />
              <p className="ml-1">{token.name}</p>
              <p className="mt-0.5 text-xs text-c-tertiary">{token.symbol}</p>
              <div className="ml-auto">
                {chain && <ChainTile size="xxs" {...chain} />}
              </div>
            </CommandItem>
          </ConditionalLink>
        );
      })}
    </ScrollArea>
  );
}
