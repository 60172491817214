import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from 'src/utils';

const badgeVariants = cva(
  'flex items-center rounded justify-center font-sans font-medium w-fit',
  {
    variants: {
      intent: {
        tertiary: 'bg-ds-tertiary text-ds-text-ds-primary',
        quarternary: 'bg-ds-quarternary text-ds-text-primary',
        inverted: 'bg-ds-inverted-primary text-ds-text-inverted-primary',
        success: 'bg-ds-badge-success-bg text-ds-badge-success-text',
        error: 'bg-ds-badge-error-bg text-ds-badge-error-text',
        info: 'bg-ds-badge-info-bg text-ds-badge-info-text',
      },
      size: {
        s: 'text-[10px] leading-[12px] h-4 gap-1 px-1',
        m: 'text-[10px] leading-[12px] h-6 gap-1 px-1',
        l: 'h-8 gap-1.5 px-2 text-sm',
        xl: 'h-10 gap-2 px-2 text-sm',
      },
    },
    defaultVariants: {
      intent: 'tertiary',
      size: 'm',
    },
  },
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  (props: BadgeProps, ref) => {
    const { className, intent, size, ...rest } = props;

    return (
      <div
        ref={ref}
        className={cn(badgeVariants({ intent, size }), className)}
        {...rest}
      />
    );
  },
);

Badge.displayName = 'Badge';

type BadgeKeyboardHintProps = Pick<BadgeProps, 'className' | 'children'>;

const BadgeKeyboardHint = React.forwardRef<
  HTMLDivElement,
  BadgeKeyboardHintProps
>((props: BadgeKeyboardHintProps, ref) => {
  const { className } = props;

  return (
    <Badge
      ref={ref}
      intent="tertiary"
      className={cn('text-ds-tertiary', className)}
      {...props}
    />
  );
});

BadgeKeyboardHint.displayName = 'BadgeKeyboardHint';

type BadgeTrendProps = {
  variant: 'uptrend' | 'downtrend' | 'neutral';
} & Omit<BadgeProps, 'intent'>;

const BadgeTrend = React.forwardRef<HTMLDivElement, BadgeTrendProps>(
  (props: BadgeTrendProps, ref) => {
    const { variant, className, children, ...rest } = props;

    return (
      <Badge
        ref={ref}
        className={className}
        {...rest}
        intent={variant === 'uptrend' ? 'success' : 'error'}
      >
        {children}
      </Badge>
    );
  },
);

BadgeTrend.displayName = 'BadgeTrend';

const BadgeCounter = React.forwardRef<HTMLDivElement, BadgeProps>(
  (props: BadgeProps, ref) => {
    const { className } = props;

    return (
      <Badge
        ref={ref}
        // className={cn(
        //   className,
        //   props.size === "s" && "!w-[22px] !h-[22px] !p-0",
        //   props.size === "m" && "!w-[26px] !h-[26px] !p-0",
        //   props.size === "l" && "!w-[32px] !h-[32px] !p-0",
        //   props.size === "xl" && "!w-[40px] !h-[40px] !p-0"
        // )}
        {...props}
      />
    );
  },
);

BadgeCounter.displayName = 'BadgeCounter';

export { Badge, BadgeCounter, BadgeKeyboardHint, BadgeTrend, badgeVariants };
