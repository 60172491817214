import React from 'react';
import { Box, MenuItem, Stack, styled } from '@mui/material';
import { Check } from 'lucide-react';
import { Badge } from 'src/shared/ui/badge';

import Tag from 'common/Tag';
import Text from 'components/primitives/Text';
import getSubscriptionPlanName from 'utils/teamManagement/getSubscriptionPlanName';
import { TeamResItem } from 'services/teamManagement/useGetTeams';

interface Props {
  onClick: (team: TeamResItem) => void;
  isSelected: boolean;
  team: TeamResItem;
}

export default function TeamMenuItem({ onClick, isSelected, team }: Props) {
  return (
    <MenuItem
      disableRipple
      sx={{
        mx: 0.5,
        borderRadius: '8px',
        px: 1,
      }}
      onClick={() => onClick(team)}
      selected={isSelected}
    >
      <MenuContentBox>
        <Stack gap={1} direction="row" alignItems="center">
          <Text size="xs">{team.name}</Text>

          {team.isSubscriptionActive && (
            <Badge size="s" intent="success">
              {team.isAdmin
                ? 'Admin'
                : getSubscriptionPlanName(team.subscriptionType)}
            </Badge>
          )}
          {team.isDemo && (
            <Badge intent="error" size="s" color="red">
              Demo
            </Badge>
          )}
        </Stack>
        {isSelected && (
          <CheckIconWrapper>
            <Check className="opacity-30" />
          </CheckIconWrapper>
        )}
      </MenuContentBox>
    </MenuItem>
  );
}

const MenuContentBox = styled(Box)({
  gap: 6,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '2px 0px',
});

const CheckIconWrapper = styled(Box)({
  '& svg': { width: '16px', height: '16px' },
  alignItems: 'center',
  display: 'flex',
});
