import { Badge } from 'src/shared/ui/badge';
import { DropdownMenuItem } from 'src/shared/ui/dropdown-menu';

import getSubscriptionPlanName from 'utils/teamManagement/getSubscriptionPlanName';
import { TeamResItem } from 'services/teamManagement/useGetTeams';

interface Props {
  onClick: (team: TeamResItem) => void;
  isSelected: boolean;
  team: TeamResItem;
}

export default function SelectTeamDropdownItem(props: Props) {
  return (
    <DropdownMenuItem
      onClick={() => props.onClick(props.team)}
      disabled={props.isSelected}
    >
      {props.team.name}{' '}
      {props.team.isDemo && (
        <Badge intent="error" size="s">
          Demo
        </Badge>
      )}
      {props.team.isAdmin && (
        <Badge intent="info" size="s">
          Admin
        </Badge>
      )}
      {props.team.subscriptionType && props.team.isSubscriptionActive && (
        <Badge intent="success" size="s">
          {getSubscriptionPlanName(props.team.subscriptionType)}
        </Badge>
      )}
      {props.isSelected && (
        <Badge intent="inverted" size="s">
          Current
        </Badge>
      )}
    </DropdownMenuItem>
  );
}
