import { Box, styled } from '@mui/material';
import { User2 } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'src/shared/ui/dropdown-menu';

import UnstyledLink from 'common/UnstyledLink';
import { useIsMobile } from 'utils/hooks';
import { useLogout } from 'utils/hooks/useLogout';

import { useAppStateContext } from '../../../../../store/AppProvider';

export default function UserPanel() {
  const {
    user: { data: userInfo },
  } = useAppStateContext();
  const isMobile = useIsMobile();

  const { handleLogout, isLoading } = useLogout();

  if (!userInfo) return null;
  return (
    <>
      <DropdownMenu>
        <div className="flex items-center gap-3">
          <DropdownMenuTrigger asChild>
            <div className="flex size-9 cursor-pointer items-center justify-center rounded-full bg-ds-quarternary text-ds-text-tertiary">
              <User2 size={24} />
            </div>
          </DropdownMenuTrigger>
          <div className="">
            <div className="text-sm">Your profile</div>
            <div className="text-ds_2xs">{userInfo.email}</div>
          </div>
        </div>
        <DropdownMenuContent side="right">
          <UnstyledLink href="/settings/personal">
            <DropdownMenuItem>Your profile</DropdownMenuItem>
          </UnstyledLink>
          <UnstyledLink href="/settings/team-management">
            <DropdownMenuItem>Your teams</DropdownMenuItem>
          </UnstyledLink>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={handleLogout}>Sign out</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}

const StyledUserInfoBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'right',
}));
