import { ComponentType } from 'react';
import { IconBaseProps } from 'react-icons';
import { Box, Stack, useTheme } from '@mui/material';
import { Badge } from 'src/shared/ui/badge';

import Tag from 'common/Tag';
import TooltipWrapper from 'common/TooltipWrapper';
import ConditionalLink from 'components/Dashboard/ConditionalLink';
import HStack from 'components/primitives/HStack';
import Text from 'components/primitives/Text';

import useSidebar from '../context/useSidebarContext';

interface Props {
  Icon: ComponentType<Partial<IconBaseProps>>;
  IconSelected?: ComponentType<Partial<IconBaseProps>>;
  title: string;
  active?: boolean;
  href?: string;
  navigateWithinTeam?: boolean;
  className?: string;
  isOpen?: boolean;
  isNew?: boolean;
}

export default function SidebarItem({
  Icon,
  IconSelected,
  href,
  title,
  active = false,
  navigateWithinTeam = false,
  className,
  isOpen,
  isNew,
}: Props) {
  const { palette } = useTheme();
  const { transitionDuration, isPermanentClosed } = useSidebar();

  return (
    <ConditionalLink
      navigateWithinTeam={navigateWithinTeam}
      condition={!!href}
      href={href!}
    >
      <Stack
        direction="row"
        display="grid"
        gridTemplateColumns={'3px 1fr'}
        alignItems={'center'}
        gap={1.5}
      >
        <Box
          sx={{
            width: '3px',
            height: '28px',
            bgcolor: 'var(--text-primary)',
            borderTopRightRadius: '4px',
            borderBottomRightRadius: '4px',
            opacity: active ? 1 : 0,
          }}
        />

        <Stack
          flex={1}
          pr={3}
          py={1}
          direction="row"
          gap={1.5}
          alignItems={'center'}
          sx={{
            opacity: active ? 1 : 0.7,
            '&:hover': {
              opacity: 1,
            },
            borderTopLeftRadius: '16px',
            borderBottomLeftRadius: '16px',
            transitionProperty: 'padding',
            transitionTimingFunction: 'ease-in-out',
            transitionDuration: transitionDuration + 'ms',
          }}
        >
          <TooltipWrapper
            className={className}
            placement="right"
            content={isPermanentClosed ? title : ''}
          >
            <HStack vcenter>
              {active && IconSelected ? (
                <IconSelected
                  color={'var(--text-primary)'}
                  size="24px"
                  opacity={active ? 1 : 0.5}
                  strokeWidth={1}
                />
              ) : (
                <Icon
                  strokeWidth={1}
                  color={
                    active ? 'var(--text-primary)' : 'var(--text-secondary)'
                  }
                  size="24px"
                  opacity={active ? 1 : 0.9}
                />
              )}
            </HStack>
          </TooltipWrapper>
          <HStack vcenter gap={1}>
            <Text
              sx={{
                opacity: isOpen ? 1 : 0,
                transitionProperty: 'opacity, padding',
                transitionTimingFunction: 'ease-in-out',
                transitionDuration: transitionDuration + 'ms',
                color: active ? 'var(--text-primary)' : 'var(--text-secondary)',
              }}
              size="sm"
              weight={active ? 'semibold' : 'regular'}
            >
              {title}
            </Text>
            {isNew && (
              <Badge size="s" intent="success">
                New
              </Badge>
            )}
          </HStack>
        </Stack>
      </Stack>
    </ConditionalLink>
  );
}
