import React from 'react';
import { Box } from '@mui/material';
import chroma from 'chroma-js';

import HStack from 'components/primitives/HStack';
import Text from 'components/primitives/Text';

export default function MobileHeaderDemoTeamOverlay() {
  return (
    <Box
      display={{
        xs: 'block',
        lg: 'none',
      }}
      sx={{
        position: 'relative',
        top: '0',
        bgcolor: 'rgba(255, 0, 0, 0.1)',
        backdropFilter: 'blur(10px)',
        WebkitBackdropFilter: 'blur(10px)',
        borderColor: 'red.200',
        width: '100%',
        height: '48px',
      }}
    >
      <HStack vcenter fullHeight fullWidth>
        <Text
          weight="medium"
          textAlign="center"
          width={'100%'}
          color="error"
          size="sm"
        >
          You are viewing the Cookie3 app in demo mode. Change your team to view
          your own data.
        </Text>
      </HStack>
    </Box>
  );
}
