import React from 'react';
import { FiPlus } from 'react-icons/fi';
import { PiCaretUpDown, PiMagnifyingGlass } from 'react-icons/pi';
import { useRouter } from 'next/router';
import { Box, Stack, styled } from '@mui/material';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from 'src/shared/ui/dropdown-menu';
import { Input } from 'src/shared/ui/form/input';
import { InputGroup } from 'src/shared/ui/form/input-group';
import { useIsLoggedIn } from 'src/store/AppProvider';

import PopperWrapper from 'common/PopperWrapper';
import Tag from 'common/Tag';
import UnstyledLink from 'common/UnstyledLink';
import HStack from 'components/primitives/HStack';
import Text from 'components/primitives/Text';
import VStack from 'components/primitives/VStack';
import TeamMenuItem from 'components/team-management/TeamDropdown/TeamMenuItem';
import useDebounce from 'utils/hooks/useDebounce';
import getSubscriptionPlanName from 'utils/teamManagement/getSubscriptionPlanName';
import { TeamResItem, useGetTeams } from 'services/teamManagement/useGetTeams';

import { useTeamStore } from '../../../store/team/teamStore';

interface Props {
  TeamDetailsPopperBox?: React.ReactNode;
}

export default function TeamDropdown({ TeamDetailsPopperBox }: Props) {
  const router = useRouter();
  const [searchInput, setSearchInput] = React.useState('');

  const debouncedSearchInput = useDebounce(searchInput, 500);

  const { teams, selectedTeam } = useGetTeams();

  const filteredTeams = React.useMemo(() => {
    if (!debouncedSearchInput) return teams;
    return teams.filter((team) =>
      team.name.toLowerCase().includes(debouncedSearchInput.toLowerCase()),
    );
  }, [debouncedSearchInput, teams]);

  const {
    setTeam,
    team: currentlyPickedTeam,
    setProject,
    setTeamAndProject,
  } = useTeamStore();

  const isLoggedIn = useIsLoggedIn();

  const onTeamClick = async (team: TeamResItem) => {
    if (team.id === currentlyPickedTeam?.id) return;
    setTeam(team);

    if (team.projects.length > 0) {
      setProject(team.projects[0]);
    }

    // Change the URL only if the route contains [teamSlug] or [projectSlug]
    const route = router.route;

    const routeHasTeamSlug = route.includes('[teamSlug]');
    const routeHasProjectSlug = route.includes('[projectSlug]');

    let updatedRoute = window.location.pathname;

    if (routeHasTeamSlug) {
      const currentTeamSlug = router.query.teamSlug as string;
      updatedRoute = updatedRoute.replace(currentTeamSlug, team.slug);
    }

    if (routeHasProjectSlug) {
      const currentProjectSlug = router.query.projectSlug as string;
      updatedRoute = updatedRoute.replace(
        currentProjectSlug,
        team.projects[0].slug,
      );
    }

    if (routeHasProjectSlug || routeHasTeamSlug) {
      setTeamAndProject(team, team.projects[0]);
      window.location.href = updatedRoute;
    } else {
      // If the route doesn't contain [teamSlug] or [projectSlug], we need to reload the page
    }
  };

  if (!isLoggedIn) return null;

  const handleMenuItemClick = (team: TeamResItem) => {
    onTeamClick(team);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <TeamDetailsBox selectedTeam={selectedTeam} />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <div className="overflow-hidden bg-ds-secondary">
          <Box sx={{ minWidth: '324px' }}>
            <Box
              bgcolor="neutral.100"
              borderBottom="1px solid"
              borderColor={'var(--border-primary)'}
            >
              <Input
                className="rounded-none rounded-t-lg"
                autoFocus
                placeholder="Find team..."
              />
            </Box>
            <Box sx={{ my: 0.5 }}>
              <VStack gap={0.25}>
                {filteredTeams.map((team) => {
                  const isSelected = team.slug === selectedTeam?.slug;

                  return (
                    <TeamMenuItem
                      key={team.id}
                      onClick={handleMenuItemClick}
                      isSelected={isSelected}
                      team={team}
                    />
                  );
                })}
              </VStack>
            </Box>

            <Box
              px={2}
              py={1.5}
              borderTop="1px solid"
              borderColor={'var(--border-primary)'}
              bgcolor="neutral.100"
            >
              <UnstyledLink href="/settings/team-management?create=true">
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Stack>
                    <Text size="xs" weight="medium">
                      Want to create a team?
                    </Text>
                    <Text size="xxs" color="tertiary">
                      Invite others and start collaborating!
                    </Text>
                  </Stack>

                  <AddIconWrapper>
                    <FiPlus color="#8F95B2" />
                  </AddIconWrapper>
                </Box>
              </UnstyledLink>
            </Box>
          </Box>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

const TeamDetailsBox = ({
  selectedTeam,
}: {
  selectedTeam: TeamResItem | undefined;
}) => {
  if (!selectedTeam) return null;
  return (
    <Box
      // border="1px solid"
      borderColor="var(--border-primary)"
      bgcolor="neutral.100"
      height="40px"
      borderRadius={3}
      pl={1.5}
      pr={0.5}
      display="flex"
      alignItems="center"
      gap={2}
      sx={{
        cursor: 'pointer',
        transition: 'background-color 0.2s ease-in-out',
        '&:hover': {
          bgcolor: 'var(--border-primary)',
        },
      }}
    >
      <Box>
        <HStack vcenter gap={1}>
          <Text size="xs" weight="medium">
            {selectedTeam.name}
          </Text>
          {selectedTeam?.isSubscriptionActive ? (
            <Tag size="small" color="green">
              {getSubscriptionPlanName(selectedTeam.subscriptionType)}
            </Tag>
          ) : (
            <Tag size="small" color="gray">
              Free
            </Tag>
          )}

          {selectedTeam?.isDemo && (
            <Tag size="small" color="red">
              Demo
            </Tag>
          )}
        </HStack>
      </Box>
      <PiCaretUpDown />
    </Box>
  );
};

const AddIconWrapper = styled(Box)({
  padding: 8,
  background: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 8,
  border: '1px solid #D8DAE5',
});
